








export default {
    cases: {
        label: 'Cases',
        path: '/grids/cases',
        icon: 'bi:journal-medical'
    },
    variants: {
        label: 'Variants',
        path: '/grids/variants',
        icon: 'ph:dna'
    }
}


